import './app.scss';
import 'lazysizes';
import swiper from './ts/swiper';
import 'intersection-observer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import navigation from './ts/navigation';
import form from './ts/form';
import 'htmx.org';
import heroProductTextSplit from './ts/hero-product-text-split';
import heroProductIntro from './ts/hero-product-intro';
import heroTextImageTextSplit from './ts/hero-text-image-text-split';
import heroTextImageIntro from './ts/hero-text-image-intro';
import filterDropdownDate from './ts/filter-dropdown-date';
import kpiIncreaseNumbers from './ts/kpi-increase-numbers';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    const filterCategories = document.querySelectorAll(
        '.filter-categories'
    ) as NodeListOf<HTMLElement>;
    if (filterCategories.length) {
        import('./ts/filter-dropdown').then((filterDropdown) => {
            filterCategories.forEach((filterCategory) => {
                filterDropdown.default.init(filterCategory);
            });
        });
    }

    const filterDates = document.querySelectorAll(
        '.filter-dates'
    ) as NodeListOf<HTMLElement>;
    if (filterDates.length) {
        filterDates.forEach((filterDate) => {
            filterDropdownDate.init(filterDate);
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(
                swiperElement,
                JSON.parse(swiperElement.dataset.swiperOptions)
            );
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).htmx.on('htmx:afterSwap', function () {
        // recreate js after sprig reloads something
        const filterDates = document.querySelectorAll(
            '.filter-dates'
        ) as NodeListOf<HTMLElement>;
        if (filterDates.length) {
            filterDates.forEach((filterDate) => {
                filterDropdownDate.init(filterDate);
            });
        }

        const allSwiper = document.querySelectorAll(
            '.swiper-container'
        ) as NodeListOf<HTMLElement>;
        if (allSwiper.length) {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.init(
                    swiperElement,
                    JSON.parse(swiperElement.dataset.swiperOptions)
                );
            });
        }
    });

    const formOverlayContainers = document.querySelectorAll(
        '.form-overlay-container'
    ) as NodeListOf<HTMLElement>;
    if (formOverlayContainers.length) {
        import('./ts/form-overlay').then((formOverlay) => {
            formOverlayContainers.forEach((container) => {
                formOverlay.default.init(container);
            });
        });
    }

    const copyLinks = document.querySelectorAll(
        '.copy-link'
    ) as NodeListOf<HTMLButtonElement>;
    if (copyLinks.length) {
        import('./ts/copy-link').then((copyLink) => {
            copyLinks.forEach((copyLinkBtn) => {
                copyLink.default.init(copyLinkBtn);
            });
        });
    }

    navigation.init();

    AOS.init();

    form.init();

    const kpiSections = document.querySelectorAll(
        '.kpis'
    ) as NodeListOf<HTMLElement>;
    if (kpiSections.length) {
        kpiSections.forEach((section) => {
            kpiIncreaseNumbers.init(section);
        });
    }

    const accordionSections = document.querySelectorAll(
        '.downloads-accordion, .faq-accordion'
    ) as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((accordionSection) => {
            accordionSections.forEach((section) => {
                accordionSection.default.init(section);
            });
        });
    }

    const heroHomeSections = document.querySelectorAll(
        '.hero-home'
    ) as NodeListOf<HTMLElement>;
    if (heroHomeSections.length) {
        import('./ts/hero-video').then((heroVideo) => {
            heroHomeSections.forEach((section) => {
                heroVideo.default.init(section);
            });
        });
        import('./ts/scroll-to-next-section').then((scrollToNextSection) => {
            heroHomeSections.forEach((section) => {
                scrollToNextSection.default.init(section);
            });
        });
        import('./ts/hero-home-text-split').then((heroHomeTextSplit) => {
            heroHomeSections.forEach((section) => {
                setTimeout(function () {
                    heroHomeTextSplit.default.init(section);
                }, 300);
            });
        });
        import('./ts/hero-home-intro').then((heroHomeIntro) => {
            heroHomeSections.forEach((section) => {
                setTimeout(function () {
                    heroHomeIntro.default.init(section);
                }, 300);
            });
        });
    }

    const featuresSections = document.querySelectorAll(
        '.features'
    ) as NodeListOf<HTMLElement>;
    if (featuresSections.length) {
        import('./ts/features').then((features) => {
            featuresSections.forEach((section) => {
                features.default.init(section);
            });
        });
    }

    const heroDetailPages = document.querySelectorAll(
        '.hero-detail-page'
    ) as NodeListOf<HTMLElement>;
    if (heroDetailPages.length) {
        import('./ts/hero-detail-page-socialmedia').then(
            (heroDetailPageSocialmedia) => {
                heroDetailPages.forEach((heroDetailPage) => {
                    heroDetailPageSocialmedia.default.init(heroDetailPage);
                });
            }
        );
    }

    const ctaBannerSections = document.querySelectorAll(
        '.cta-banner'
    ) as NodeListOf<HTMLElement>;
    if (ctaBannerSections.length) {
        import('./ts/cta-banner').then((ctaBanner) => {
            ctaBannerSections.forEach((section) => {
                ctaBanner.default.init(section);
            });
        });
    }

    const eventsArticlesSections = document.querySelectorAll(
        '.events-and-articles'
    ) as NodeListOf<HTMLElement>;
    if (eventsArticlesSections.length) {
        import('./ts/events-and-articles').then((eventsAndArticles) => {
            eventsArticlesSections.forEach((section) => {
                eventsAndArticles.default.init(section);
            });
        });
    }

    const productTeaserSections = document.querySelectorAll(
        '.product-teaser'
    ) as NodeListOf<HTMLElement>;
    if (productTeaserSections.length) {
        import('./ts/product-teaser').then((productTeaser) => {
            productTeaserSections.forEach((section) => {
                productTeaser.default.init(section);
            });
        });
    }

    const textSections = document.querySelectorAll(
        'section.text'
    ) as NodeListOf<HTMLElement>;
    if (textSections.length) {
        import('./ts/text-animation').then((textAnimation) => {
            textSections.forEach((section) => {
                setTimeout(function () {
                    textAnimation.default.init(section);
                }, 300);
            });
        });
    }

    // const heroProductAnimations = document.querySelectorAll(
    //     '.hero-product'
    // ) as NodeListOf<HTMLElement>;
    // if (heroProductAnimations.length) {
    //     heroProductAnimations.forEach((section) => {
    //         heroProductAnimation.init(section);
    //     });
    // }

    const heroProductSections = document.querySelectorAll(
        '.hero-product'
    ) as NodeListOf<HTMLElement>;
    if (heroProductSections.length) {
        heroProductSections.forEach((section) => {
            // heroVideo.init(section);
            // scrollToNextSection.init(section);
            setTimeout(function () {
                heroProductTextSplit.init(section);
                heroProductIntro.init(section);
            }, 300);
        });
    }

    const heroTextImageSections = document.querySelectorAll(
        '.hero-text-image'
    ) as NodeListOf<HTMLElement>;
    if (heroTextImageSections.length) {
        heroTextImageSections.forEach((section) => {
            // heroVideo.init(section);
            // scrollToNextSection.init(section);
            setTimeout(function () {
                heroTextImageTextSplit.init(section);
                heroTextImageIntro.init(section);
            }, 300);
        });
    }

    const verticalTabsSections = document.querySelectorAll(
        '.vertical-tabs-section'
    ) as NodeListOf<HTMLElement>;
    if (verticalTabsSections.length) {
        import('./ts/vertical-tabs').then((verticalTabsSection) => {
            verticalTabsSections.forEach((section) => {
                verticalTabsSection.default.init(section);
            });
        });
    }
})();
