export default {
    init(filterDate: HTMLElement) {
        const dropdownWrappers =
            filterDate.querySelectorAll('.dropdown-wrapper');

        if (!dropdownWrappers) return;

        const content = document.querySelector('.content');

        if (!content) return;

        const area = document.createElement('div');
        area.classList.add('fixed');
        area.classList.add('inset-0');
        area.classList.add('area');
        area.classList.add('hidden');
        content.appendChild(area);

        dropdownWrappers.forEach((wrapper) => {
            const dropdownButton = wrapper.querySelector('.dropdown-button');
            const dropdownContent = wrapper.querySelector('.dropdown-content');

            if (!dropdownButton || !dropdownContent) return;

            dropdownButton.addEventListener('click', () => {
                if (dropdownContent.classList.contains('hidden')) {
                    // Open Drodown
                    dropdownButton.classList.add('active');
                    dropdownContent.classList.remove('hidden');

                    if (area) {
                        area.classList.remove('hidden');

                        area.addEventListener('click', () => {
                            if (dropdownButton.classList.contains('active')) {
                                dropdownContent.classList.add('hidden');
                                dropdownButton.classList.remove('active');
                                area.classList.add('hidden');
                            }
                        });
                    }
                } else if (dropdownButton.classList.contains('active')) {
                    // Close Dropdown
                    dropdownContent.classList.add('hidden');

                    if (area) {
                        area.classList.add('hidden');
                    }
                }
            });
        });
    }
};
